import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '160382',
  },
  flixmedia: {
    distributorId: 18442,
    enabled: true,
  },
  price: {
    hasSalePrimaryColor: false,
  },
  powerSupply: {
    histrogram: {
      included: 'https://bilder.jelmoli-shop.ch/i/empiriecom/FuAG_included.svg',
      notIncluded: 'https://bilder.jelmoli-shop.ch/i/empiriecom/FuAG_not_included.svg',
      specification: 'https://bilder.jelmoli-shop.ch/i/empiriecom/FuAG_specifications.svg',
    },
  },
};
